<template>
    <div class="page">
        <div class="title">
            开票信息
            <div
                class="custom_button no_select title_button"
                @click="onGetQrcodeClick"
            >
                查看开票二维码
            </div>
        </div>

        <el-divider></el-divider>

        <el-form
            ref="form"
            :rules="rules"
            label-position="top"
            :model="form"
            label-width="80px"
        >
            <el-form-item label="企业名称" prop="company_name">
                <el-input v-model="form.company_name"></el-input>
            </el-form-item>

            <el-form-item label="社会统一信用代码" prop="social_code">
                <el-input v-model="form.social_code"></el-input>
            </el-form-item>

            <el-form-item label="开户银行" prop="bank_name">
                <el-input v-model="form.bank_name"></el-input>
            </el-form-item>

            <el-form-item label="银行账号" prop="bank_account">
                <el-input v-model="form.bank_account"></el-input>
            </el-form-item>

            <el-form-item label="联系电话" prop="contact">
                <el-input v-model="form.contact"></el-input>
            </el-form-item>

            <el-form-item label="企业地址" prop="address">
                <el-input v-model="form.address"></el-input>
            </el-form-item>

            <el-form-item class="form_btns">
                <div class="form_btns">
                    <div
                        class="custom_button plain no_select"
                        @click="onSubmit"
                    >
                        提交认证
                    </div>
                    <div class="custom_button no_select" @click="onReset">
                        重置
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <el-dialog title="查看二维码" :visible.sync="dialogVisible" width="30%">
            <el-image :src="qrcode"></el-image>
            <span slot="footer" class="dialog-footer">
                <div
                    class="custom_button no_select"
                    @click="dialogVisible = false"
                >
                    确 定
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            dialogVisible: false,
            qrcode: "",
            form: {
                company_name: "",
                social_code: "",
                bank_name: "",
                bank_account: "",
                contact: "",
                address: "",
            },
            rules: {
                company_name: [
                    {
                        required: true,
                        message: "请填写企业名称",
                        trigger: "blur",
                    },
                ],
                social_code: [
                    {
                        required: true,
                        message: "请填写社会统一信用代码",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
                        message: "请填写正确的社会统一信用代码",
                        trigger: "blur",
                    },
                ],
                bank_name: [
                    {
                        required: true,
                        message: "请填写开户银行",
                        trigger: "blur",
                    },
                ],
                bank_account: [
                    {
                        required: true,
                        message: "请填写银行账号",
                        trigger: "blur",
                    },
                ],
                contact: [
                    {
                        required: true,
                        message: "请填写联系电话",
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "请填写企业地址",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        let loading = this.$loading();
        this.getEnterpriseInvoiceInfo()
            .then((res) => {
                console.log(res);
                if (res.data.data.info) this.form = res.data.data.info;
                loading.close();
            })
            .catch((e) => {
                console.error(e);
                loading.close();
            });
    },
    methods: {
        ...mapActions("enterprise", [
            "getEnterpriseInvoiceInfo",
            "postEnterpriseInvoiceInfo",
            "getEnterpriseInvoiceQrcode",
        ]),

        /**
         * 获取开票二维码
         */
        onGetQrcodeClick() {
            let loading = this.$loading();
            this.getEnterpriseInvoiceQrcode()
                .then((res) => {
                    console.log(res);
                    this.qrcode = res.data.data.qrcode_url;
                    this.dialogVisible = true;
                    loading.close();
                })
                .catch((e) => {
                    console.log(e);
                    loading.close();
                    this.$message.error("获取开票二维码失败");
                });
        },

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postEnterpriseInvoiceInfo(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },

        onReset() {
            this.$confirm("重置表单, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$refs.form.resetFields();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
    position: relative;

    .title_button {
        width: 124px;
        position: absolute;
        right: 0;
        top: -10px;
    }
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

.form_btns {
    display: flex;
    align-items: center;

    .custom_button {
        width: 142px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
    }

    > div:not(:first-child) {
        margin-left: 20px;
    }
}
</style>
